@media (max-width: 470px) {
  .mt-15-470px {
    /* Your styles for screens less than 470px */
    /* For example: */
    margin-top: 30px !important;
  }

  .mx-w-60 {
    /* Your styles for screens less than 470px */
    /* For example: */
    max-width: 60% !important;
  }
}

@media (max-width: 440px) {
  .mt-15-470px {
    /* Your styles for screens less than 470px */
    /* For example: */
    margin-top: 30px !important;
  }

  .mx-w-60 {
    /* Your styles for screens less than 470px */
    /* For example: */
    max-width: 70% !important;
  }
}

@media (max-width: 380px) {
  .mt-15-470px {
    /* Your styles for screens less than 470px */
    /* For example: */
    margin-top: 30px !important;
  }

  .mx-w-60 {
    /* Your styles for screens less than 470px */
    /* For example: */
    max-width: 80% !important;
  }
}

.custom-badge-for-journal-entries {
  display: inline-block;
  background: #F1F3F5;
  padding: 5px;
  color: #4d4e4e;
  border: 1px solid #B5BDC3;
  border-radius: 10px;
  margin-top: 5px;
  min-width: 150px;
}

@media (max-width: 375px) {
  .mt-15-470px {
    /* Your styles for screens less than 470px */
    /* For example: */
    margin-top: 30px !important;
  }

  .mx-w-60 {
    /* Your styles for screens less than 470px */
    /* For example: */
    max-width: 60% !important;
  }
}

.date-block-inactive {
  display: inline-block;
  min-width: 50px;
  height: 30px;
  line-height: 30px;
  background: #B5BDC3;
  text-align: center;
  color: black;
  border-radius: 30px;
  margin-right: 30px;
  cursor: pointer;
}

.date-block-active {
  display: inline-block;
  min-width: 50px;
  height: 30px;
  line-height: 30px;
  background: black;
  text-align: center;
  color: white;
  border-radius: 30px;
  margin-right: 30px;
  cursor: pointer;
}

.single-activity-container-for-calendar {
  min-height: 100px;
  box-shadow: 0 4px 3px rgba(181, 189, 195, 0.5);
  padding: 15px;
  margin-top: 30px;
}

.bg-primary-4-custom {
  background: #dfe6e9 !important;
}
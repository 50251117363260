/* .ant-menu-item-selected .ant-menu-item-icon path {
    fill: none !important;
    stroke: #ffffff !important;
    stroke-width: 0.6 !important
} */

.ant-menu-item-selected .ant-menu-item-icon svg {
    stroke: #ffffff !important;
    stroke-width: 0.6 !important
}

.ant-menu-item-selected .ant-menu-item-icon {
    stroke: #ffffff !important;
    /* stroke-width: 0.6 !important */
}
.slick-slide {
    margin-right: 10px;
    height: auto;

}


.slick-track {
    display: flex;

}
.ant-list-item-main{
    max-width: 20rem;
    width: 100%;
}

.ant-list-item-extra{
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 576px){
    .ant-list-item {
    flex-wrap: wrap;
}
}
@media (max-width: 768px) {
  .recordsShow {
    display: none;
  }
}

@media (min-width: 768px) {
  .recordsHide {
    display: none;
  }
}
.ant-upload-list-item-name {
  display: none !important;
}

.ant-upload-list-item-card-actions {
  margin-left: 8px;
}

.ant-upload-list-item-list-type-picture {
  border: none !important;
  padding: 0px !important;
}

.ant-space {
  gap: 0px !important;
}

.ant-list-item-meta-title {
  display: flex;
}

.ant-list-item-meta-description {
  /* display: flex; */
  overflow-wrap: break-word;
  
}
